<template>
  <start-layout>
    <div class="section section_mobile-secondary">
      <div class="section__content">
        <div class="account">
          <div class="row content-center">
            <div class="col col_10 col_desktop-12">
              <div class="account__wysiwyg wysiwyg align-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94" fill="none">
                  <path d="M47 11.0156C36.3705 11.0156 27.7227 19.6634 27.7227 30.293C27.7227 36.8919 31.0505 42.9637 36.6246 46.535C42.3165 50.1817 49.3035 50.5692 55.7965 47.582C57.1781 46.9464 57.783 45.3109 57.1472 43.9292C56.5116 42.5477 54.8764 41.9427 53.4944 42.5783C48.7467 44.7627 43.6806 44.5143 39.5958 41.8974C35.61 39.3438 33.2305 35.0056 33.2305 30.293C33.2305 22.7004 39.4075 16.5234 47 16.5234C54.5925 16.5234 60.7695 22.7004 60.7695 30.293C60.7695 31.8115 59.5341 33.0469 58.0156 33.0469C56.628 33.0469 55.3319 31.9945 55.2621 30.3348C55.2619 30.3209 55.2617 30.3069 55.2617 30.293C55.2617 25.7375 51.5555 22.0312 47 22.0312C42.4445 22.0312 38.7383 25.7375 38.7383 30.293C38.7383 34.8485 42.4445 38.5547 47 38.5547C49.1143 38.5547 51.0448 37.7555 52.5076 36.4445C53.9703 37.7557 55.9012 38.5547 58.0156 38.5547C62.5711 38.5547 66.2773 34.8485 66.2773 30.293C66.2773 19.6634 57.6295 11.0156 47 11.0156ZM49.7152 30.7347C49.5026 32.0435 48.3678 33.0469 47 33.0469C45.4815 33.0469 44.2461 31.8115 44.2461 30.293C44.2461 28.7745 45.4815 27.5391 47 27.5391C48.412 27.5391 49.5777 28.6076 49.7346 29.9781C49.6935 30.2283 49.6867 30.483 49.7152 30.7347Z" fill="#00A961"/>
                  <path d="M88.3084 36.1636C88.3077 35.4514 88.0209 34.7396 87.5019 34.2206L77.293 24.0117V2.75391C77.293 1.23302 76.06 0 74.5391 0H19.4609C17.94 0 16.707 1.23302 16.707 2.75391V24.0117L6.49812 34.2206C6.48857 34.2301 6.48049 34.2408 6.47113 34.2505C5.95505 34.7822 5.69269 35.4822 5.69159 36.1636C5.69159 36.1654 5.69141 36.1671 5.69141 36.1689V85.7383C5.69141 90.2997 9.40294 94 13.9531 94H80.0469C84.6072 94 88.3086 90.2901 88.3086 85.7383V36.1689C88.3086 36.1671 88.3084 36.1654 88.3084 36.1636ZM77.293 31.8008L81.6601 36.168L77.293 40.5351V31.8008ZM22.2148 25.1533C22.2148 25.1527 22.2148 25.152 22.2148 25.1514V5.50781H71.7852V25.1514C71.7852 25.152 71.7852 25.1527 71.7852 25.1533V46.0429L56.875 60.9531H37.125L22.2148 46.0429V25.1533ZM16.707 31.8008V40.5351L12.3399 36.168L16.707 31.8008ZM11.1992 84.5976V42.8164L32.0898 63.707L11.1992 84.5976ZM15.094 88.4922L37.125 66.4609H56.875L78.906 88.4922H15.094ZM82.8008 84.5976L61.9102 63.707L82.8008 42.8164V84.5976Z" fill="#00A961"/>
                </svg>

                <h1 v-if="!this.user.user_profile.joining">Check your email!</h1>
                <h1 v-else>Thank you for registering your account!</h1>

                <p v-if="this.user.forgot_confirm">
                  Password restore instructions will send to your email.
                </p>
                <p v-else-if="this.user.user_profile.joining">
                  Pending confirmation from {{ this.user.user_profile.company_name }} of your request will be sent to your email: {{ this.user.user_profile.email }}. Thank you for your patience, we look forward to having you join our platform and network!
                </p>
                <p v-else>
                  Your account has been successfully created. The verification
                  email has been sent to your email box: {{ this.user.user_profile.email }}. Please follow the link
                  from the verification email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ 'popup-visible' : this.user.confirmed_email }">
      <div class="popup-wrap" @click.self="closePopup">
        <div class="popup successfully-popup" :class="{ 'active' : this.user.confirmed_email }">
          <div class="popup__header">
            <div class="popup__title">
              <span class="title-text">Successfully</span>
            </div>
            <div @click.prevent="closePopup" class="popup-remove">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
              </svg>
            </div>
          </div>
          <div class="popup__content">
            <div class="popup__section">
              <div class="wysiwyg">
                Thank you, your email {{ this.user.user_profile.email }} has been verified. Enjoy using our platform and good luck!
              </div>
            </div>
          </div>
          <div class="popup__footer">
            <div class="btn-group btn-group_secondary content-right">
              <a href="#" @click.prevent="closePopup" class="btn btn_primary">
                <span class="btn__text">Ok</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </start-layout>
</template>

<script>
import StartLayout from "../layouts/Start.vue"
import store from '../store'
import { router } from '../router'

export default {
  name: "ConfirmEmail",
  components: {
    StartLayout,
  },
  props: ['key'],
  beforeMount() {
    // console.log(router.currentRoute._value.params);
    if(router.currentRoute._value.params.signature) {
      var data = {};
      data.confirmation_key = router.currentRoute._value.params.key;
      data.hash = router.currentRoute._value.params.hash;
      data.expires = router.currentRoute._value.params.expires;
      data.signature = router.currentRoute._value.params.signature;
      store.dispatch('USER_CONFIRM', data);
    }
    if(router.currentRoute._value.params.uid && router.currentRoute._value.params.email) {
      var payload = {};
      payload.uid = router.currentRoute._value.params.uid;
      payload.email = router.currentRoute._value.params.email;
      store.dispatch('CANCEL_REQUEST_JOINING', payload);
    }
  },
  methods: {
    async closePopup () {
      await store.dispatch('USER_GET_INIT_APP');
      router.push({name: "home"});
    }
  },
  computed: {
    user: () => store.state.user
  }
}
</script>
